<template>
  <v-dialog v-model="dialogActive" width="700">
    <v-card>
      <v-card-title>{{ id === '' ? 'New' : 'Edit'}} Category</v-card-title>
      <v-card-text style="min-height:300px">
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="name" :rules="nameRules" :error-messages="nameValid ? '' : 'Name is already used by another category'" label="Category Name (Code)" outlined></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="label" label="Visible Label" outlined hide-details></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-switch v-model="active" :label="active ? 'Active; Click to Inactivate' : 'Inactive; Click to Activate'" outlined hide-details></v-switch>
          </v-col>
          <v-col cols="6">
            <v-switch v-model="loginRequired" :label="loginRequired ? 'Login Required; Click to change' : 'Login Not Required; Click to change'" outlined hide-details></v-switch>
          </v-col>
          <v-col cols="12">
            <v-textarea v-model="notes" label="Notes (not currently visible outside of admins)" outlined></v-textarea>
          </v-col>
          <v-col cols="6">
            <v-text-field :value="count" label="Item Count" readonly disabled hide-details></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field :value="last" label="Last Item Date" disabled hide-details></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="dialogActive = false">Cancel</v-btn>
        <v-btn :disabled="isSaving" color="success" text @click="save">{{ isSaving ? 'Saving...' : 'Save' }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn :to="'/library/archives/' + name.replaceAll(' ', '_')">View Entries</v-btn>
        <v-btn v-if="folder != null && folder !== ''" :href="'https://drive.google.com/drive/u/0/folders/' + folder" target="_blank" text>View Google Drive Folder</v-btn>
        <v-btn v-else :disabled="name.length < 4 || !nameValid" text @click="createFolder">Create Google Drive Folder</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import { stringFormatDate } from '@/helpers/formatters'

export default {
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  setup (props, { root, emit }) {
    const dialogActive = ref(false)

    function activate () {
      dialogActive.value = true
    }

    const name = ref('')
    const nameRules = ref([
      (v) => !!v || 'Name is required',
      (v) => v.length >= 4 || 'Must be at least 4 characters long'
    ])
    const nameValid = ref(true)
    const label = ref('')
    const active = ref(false)
    const loginRequired = ref(true)
    const folder = ref('')
    const count = ref(0)
    const last = ref('')
    const notes = ref('')

    const service = root.$feathers.service('library/archive/category')

    watch(name, () => {
      service.find({ query: { name: name.value, $limit: 1 } }).then(({ data, total }) => {
        if (total > 0 && data[0]._id !== props.id) nameValid.value = false
        else nameValid.value = true
      })
    })

    watch(() => props.id, () => {
      if (props.id != null && props.id !== '') {
        service.get(props.id).then((doc) => {
          name.value = doc.name
          label.value = doc.label
          active.value = doc.active
          loginRequired.value = doc.loginRequired
          folder.value = doc.folder
          count.value = doc.count || 0
          last.value = 'last' in doc ? stringFormatDate(doc.last).substring(0, 17) : ''
        })
      } else {
        name.value = ''
        label.value = ''
        active.value = false
        loginRequired.value = false
        folder.value = ''
        count.value = 0
        last.value = ''
      }
    })

    const isSaving = ref(false)
    async function save () {
      isSaving.value = true
      const obj = {
        name: name.value,
        label: label.value,
        active: active.value,
        loginRequired: loginRequired.value,
        notes: notes.value
      }
      if (props.id === '') {
        try {
          const val = await service.create(obj)
          emit('update')
          dialogActive.value = false
          isSaving.value = false
          return val
        } catch (e) {
          console.error(e)
          root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error creating category: ' + obj })
        }
      } else {
        try {
          const val = await service.patch(props.id, obj)
          emit('update')
          dialogActive.value = false
          isSaving.value = false
          return val
        } catch (e) {
          console.error(e)
          root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error updating category: ' + obj })
        }
      }
      isSaving.value = false
    }

    async function createFolder () {
      const res = await root.$feathers.service('service/drive-folder').create({ parent: '1ZTDIWVsdjFDFuQe58MErEn-FxKRii5nr', name: name.value })
      if ('id' in res) {
        if (props.id === '') {
          const det = await save()
          console.log(det)
          await service.patch(det._id, { folder: res.id })
        } else {
          await service.patch(props.id, { folder: res.id })
        }
        folder.value = res.id
      }
    }

    return {
      dialogActive,
      activate,
      name,
      nameValid,
      nameRules,
      label,
      active,
      loginRequired,
      folder,
      count,
      last,
      notes,
      isSaving,
      save,
      createFolder,
      stringFormatDate
    }
  }
}
</script>
